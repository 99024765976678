import schttp from 'public/src/services/schttp/index'
import { asyncLoadFile } from '@shein/common-function'
const { REPORT_URL } = typeof gbCommonInfo === 'undefined' ? {} : gbCommonInfo

// geetest的初始化因为语言上有一些差异，需要转换
function parseLanguage(lang) {
  if (lang === 'pt-br') {
    return 'pt'
  }
  if (lang === 'he') {
    return 'iw'
  }
  return lang
}

function arrayFuncShift(arr, data) {
  const l = arr.length
  for (let i = 0; i < l; i++) {
    typeof arr[i] === 'function' && arr[i](data)
  }
  arr = []
}

const biName = {
  forgetPassword: 'click_fpwordriskverify',
  forgetPhone: 'click_fpwordriskverify',
  resetPhone: 'click_fpwordriskverify',
  loginEmail: 'click_loginriskverify',
  loginPhone: 'click_loginriskverify',
  phoneCodeSend: 'click_loginriskverify',
  signupEmail: 'click_registerriskverify',
  signupPhone: 'click_registerriskverify',
  quickRegister: 'click_registerriskverify',
}

const poskeys = ['JyRisk', 'JyRiskReg', 'JyRiskZTReg'] // 登录、注册、专题注册

class GeeTest {
  abtResult = {} // abt结果
  isSupportGeetest = true // 该站点是否支持极验
  isShouldInit = true // 是否应该初始化极验验证器，基于abt结果和极验服务状态
  info = {} // 注册结果信息
  challange = '' // 流水号
  verifyCb = [] // 验证结果回调
  initCb = [] // 初始化回调
  gtInstance = null
  from = '' // 调用来源
  isReInitStatus = false
  isGtInstanceReady = false // 极验实例是否已经准备完成
  errorTriggerTimes = 0 // 错误触发次数
  isInitError = false // 实例初始化中出现错误
  reInitTimes = 0
  isInitIng = false
  riskMaskValue = ''
  isResetting = false // 重置流水号请求中
  isSetXhrListen = false // 是否已经设置过xhr事件监听，防止重复监听
  chanllangeTimestap = 0
  ajaxParams = ''
  staticUrl = ''
  fullpageJs = ''

  // 获取流水号
  register(cb, type = '') {
    schttp({ url: `/api/risk/geetest/register?riskType=${type}`, timeout: 20000 })
      .then(res => {
        const { status, data } = res
        this.fullpageJs = data?.fullpage || ''
        this.staticUrl = data?.static_servers?.[0] || ''
        this.info = data
        this.isShouldInit = status == 0
        if (this.isShouldInit && this.isSupportGeetest) {
          this.chanllangeTimestap = Date.now()
          this.initCb.push(cb)
          this.listenXhrComplete()
          this.fetchFullPageResource(!!type)
          return
        }
        this.isInitIng = false
        typeof cb === 'function' && cb({ status: 'pass' })
      })
      .catch(err => {
        this.isInitError = true
        this.isInitIng = false
        this.errorReport(
          err,
          err.statusText,
          `${location.origin}/${gbCommonInfo.langPath}geetest/register`
        )
        if (!this.isReInitStatus) typeof cb === 'function' && cb({ status: 'error' })
      })
  }

  // 拦截ajax.php作数据监听
  listenXhrComplete() {
    if (this.isSetXhrListen) return
    this.isSetXhrListen = true
    const origOpen = XMLHttpRequest.prototype.open
    const self = this
    XMLHttpRequest.prototype.open = function () {
      this.addEventListener('load', function () {
        if (/api\/risk\/geetest\/ajax\.php$/.test(this.responseURL)) {
          self.sendGtVerifyResult(this.responseText)
        }
        if (/api\/risk\/geetest\/reset\.php$/.test(this.responseURL)) {
          self.chanllangeTimestap = Date.now()
          self.recallVefiry()
          self.isResetting = false
        }
      })
      if (/api\/risk\/geetest\/ajax\.php$/.test(arguments[1])) self.isSendingAjaxPhP = true
      origOpen.apply(this, arguments)
    }

    const orgSend = XMLHttpRequest.prototype.send
    XMLHttpRequest.prototype.send = function () {
      if (self.isSendingAjaxPhP === true) {
        self.ajaxParams = arguments[0]
        self.isSendingAjaxPhP = false
      }
      orgSend.apply(this, arguments)
    }
  }

  // 数据上报
  sendGtVerifyResult(responseText) {
    let res = {}
    try {
      res = JSON.parse(responseText)
    } catch (e) {
      console.log(e)
    }
    const data = res.data || {}

    this.riskMaskValue = data && data.risk

    let isSuccess = 1
    if (res.status === 0 && data.result === 'success' && data.sub_type === 'fullpage') {
      isSuccess = 0
    }
    const actName = biName[this.from]
    if (!this.from) return
    const params = typeof SaPageInfo !== 'undefined' ? SaPageInfo : { start_time: Date.now() }
    const actParams = {
      verification_result: isSuccess,
      sessionid_shein: res.token || ''
    }
    if (data.sub_type) actParams.sub_type = data.sub_type
    if (data.score) actParams.score = data.score
    if (data.result) actParams.success = data.result === 'success' ? 0 : 1
    const biData = Object.assign({}, params, {
      activity_name: actName,
      activity_param: actParams
    })
    sa('send', biData)
  }

  /**
   * 获取极验abt结果
   * @param {function} cb 结果回调函数
   */
  getGtAbtResult(cb) {
    // 不支持的站点
    if (!this.isSupportGeetest) return typeof cb === 'function' && cb()
    this.isInitIng = true
    // window._abt_server_provider.getUserAbtResult({ posKeys: poskeys.join(',') }).then((abtinfo) => {
    let abtInfo = {
      JyRisk: {
        param: 'on',
        poskeys: 'JyRisk'
      },
      JyRiskReg: {
        param: 'on',
        poskeys: 'JyRiskReg'
      },
      JyRiskZTReg: {
        param: 'on',
        poskeys: 'JyRiskZTReg'
      }
    }

    this.setAbtResult(abtInfo)
    if (this.showInitGTinstance()) {
      this.register(cb)
      return
    }
    this.isShouldInit = false
    this.isInitIng = false
    typeof cb === 'function' && cb()
    // }).catch(() => {
    //   this.isShouldInit = false
    //   this.isInitIng = false
    //   typeof cb === 'function' && cb()
    // })
  }

  setAbtResult(res) {
    poskeys.forEach(k => {
      this.abtResult[k] = res[k] || {}
    })
  }

  getItemResult(key) {
    if (this.reInitTimes >= 4) return true
    return !(this.abtResult[key] && this.abtResult[key].param === 'off') && this.isShouldInit
  }

  showInitGTinstance() {
    return true
    // const res = this.abtResult
    // return poskeys.some((k) => res[k] && res[k].param === 'on')
  }
  urlConversion(path) {
    return path.replace(/http:/g, 'https:')
  }

  fetchFullPageResource() {
    if (!this.isShouldInit || !this.isSupportGeetest) return
    if (this.isGtInstanceReady == true) {
      this.initGeetestInstance()
      return
    }
    this.staticUrl = this.urlConversion(this.staticUrl)
    const fullpageUrl = `${this.staticUrl}/${this.fullpageJs}`
    asyncLoadFile({
      label: 'script',
      attrs: { async: true, src: fullpageUrl }
    })
      .then(() => {
        this.initGeetestInstance()
      })
      .catch(err => {
        console.log(err)
        this.errorReport(err, 'fullpage.js load error', '')
        // 初始化阶段出现错误
        this.isInitError = true
        this.isInitIng = false
        if (this.initCb.length) {
          arrayFuncShift(this.initCb, { status: 'error' })
        }
      })
  }

  initGeetestInstance() {
    const options = {
      ...this.info,
      ...{
        product: 'bind',
        lang: parseLanguage(gbCommonInfo.appLanguage),
        timeout: 20000,
        pure: true
      }
    }
    try {
      const captchaObj = new window.Geetest(options)
      captchaObj.onReady(() => {
        this.gtInstance = captchaObj
        this.isInitError = false
        this.isGtInstanceReady = true
        this.isInitIng = false
        arrayFuncShift(this.initCb, { status: 'complete' })
        this.initCb = []
        // 初始化未完成时候就开始调用验证，在初始化完成之后需要手动触发验证
        if (this.verifyCb.length > 0) this.gtInstance.verify()
      })
      captchaObj.onSuccess(() => {
        setTimeout(() => {
          const { geetest_challenge } = captchaObj.getValidate()
          const data = {
            status: 'success',
            challenge: geetest_challenge,
            risk: this.riskMaskValue
          }
          this.verifyCb.forEach(callback => {
            callback(data)
            this.verifyCb.shift()
          })
        }, 0)
      })
      captchaObj.onClose(() => {
        this.verifyCb.forEach(callback => {
          callback({ status: 'close' })
          this.verifyCb.shift()
        })
      })
      captchaObj.onError(info => {
        this.errorReport(info, 'sdk error', '')
        if (this.isInitIng) {
          this.isInitError = true
          this.isInitIng = false
        }
        // 初始化阶段出现错误
        if (this.initCb.length) {
          arrayFuncShift(this.initCb, { status: 'error' })
          this.initCb = []
          return
        }
        arrayFuncShift(this.verifyCb, { status: 'error' })
        this.verifyCb = []
      })
    } catch (e) {
      this.isInitError = true
      this.isInitIng = false
      this.errorReport(e.stack, 'sdk init error', '')
      if (this.initCb.length) {
        arrayFuncShift(this.initCb, { status: 'error' })
      }
    }
  }

  /**
   * 极验验证结果回调
   * @param cb 验证结果回调方法
   */
  verify(cb, from = '') {
    if (this.isInitError || this.reInitTimes >= 4) {
      // 实例初始化阶段出现错误
      cb({ status: 'error' })
      return
    }
    this.from = from
    typeof cb === 'function' && this.verifyCb.push(cb)
    this.gtInstance?.verify()
  }

  verifyPromise(poskey, from = '') {
    return new Promise(resolve => {
      if (!this.getItemResult(poskey)) return resolve({ status: 'abt-off' })
      this.verify(data => {
        resolve(data)
      }, from)
    })
  }

  recallVefiry() {
    if (this.isResetting && this.verifyCb.length > 0) {
      this.gtInstance.verify()
    }
  }

  reset() {
    this.isResetting = true
    this.gtInstance && this.gtInstance.reset()
  }

  reInitGt(cb) {
    if (this.reInitTimes > 5) return cb()
    this.reInitTimes++
    this.isReInitStatus = true
    if (this.isInitIng) {
      return typeof cb === 'function' && this.initCb.push(cb)
    }
    this.isInitIng = true
    this.getGtAbtResult(cb)
  }

  errorReport(err, type, path) {
    const errInfo = err && err.target ? err.target : err
    this.errorTriggerTimes++
    const { SA_REPORT_URL } = REPORT_URL
    window.ErrorJs &&
      window.ErrorJs.sendError('error', `${SA_REPORT_URL}/unusual`, {
        errorMark: 'GeeTestBrowserPwaError',
        errorType: type,
        errorInfo: JSON.stringify(errInfo),
        errorPath: path,
        errrorTimes: this.errorTriggerTimes
      })
  }
}

export default GeeTest
